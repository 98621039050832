import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { useI18next, I18nextContext } from "gatsby-plugin-react-i18next"
import {
  Box,
  Text,
  FaqComponent,
  StartYoung,
  useMediaQuery,
} from "@youngagency/young-ui"
import { Container, Spacer } from "../components/styled"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { getImage } from "gatsby-plugin-image"
import {
  createArrayFromTranslationQuery,
  faqLinkParser,
  parseColor,
} from "../utils/helpers"
import HomeCardSection from "../components/home/homeCards.component"
import TestimonialLuca from "../images/testimonial-1.png"
import TestimonialJessica from "../images/testimonial-2.png"
import TestimonialAndrea from "../images/testimonial-3.png"
import TestimonialCardsSection from "../components/home/testimonialCards.component"
import StepIcon from "../images/step-icon.svg"
import Seo from "../components/seo"
import { createGAEvent } from "../utils"
import { Helmet } from "react-helmet"
import StyledText from "../components/shared/StyledText"
import GooglePlayStore from "../images/button-play-store.svg"
import AppleStore from "../images/button-app-store.svg"
import {
  FirstStepImages,
  HeroImages,
  LastStepImage,
  SecondStepImages,
  ThirdStepImages,
} from "../feature/home/components/LocalizedImage"

const CardsWrapperSection = styled.section`
  width: 100%;

  & .card-section-row {
    gap: 49px;
  }

  & .landing-card-section:last-child {
    background: var(--green);
    margin-top: 70px;
    .card-text-container {
      color: white;
    }
  }

  @media screen and (max-width: 1024px) {
    & .card-section-row {
      gap: 64px !important;

      h2 {
        font-size: 48px !important;
        line-height: 57px;
      }
    }
  }

  @media screen and (min-width: 40em) {
    & .card-section-row {
      gap: 117px;
    }
    & .landing-card-section:nth-child(2n) {
      .card-text-container {
        order: 2;
      }
      .card-img-container {
        order: 1;
      }
    }
  }
`

const LastCardContainer = styled(Container)`
  & > div > div > div > div > div {
    background: #ff298a !important;
  }

  & img:first-child {
    margin-bottom: 0px !important;
  }
`

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  & > *:not(:first-of-type) {
    margin-left: 8px;
  }
  img {
    height: 48px;
  }
  @media screen and (min-width: 767px) {
    img {
      height: auto;
    }
  }
`

const StyledLink = styled.a``

const IndexPage = ({ pageContext: { i18n }, data }) => {
  const { t } = useI18next()
  const context = React.useContext(I18nextContext)
  const lng = context.language
  const { isMobile } = useMediaQuery()
  const homeImages = [
    <FirstStepImages />,
    <SecondStepImages />,
    <ThirdStepImages />,
    <LastStepImage />,
  ]
  const homeCardsArray = React.useMemo(
    () =>
      createArrayFromTranslationQuery(
        t,
        "tiktok.homeCards.card",
        4,
        homeImages,
        "title",
        "subtitle",
        "img"
      ),
    []
  )

  const homeTestimonialCards = React.useMemo(
    () =>
      createArrayFromTranslationQuery(
        t,
        "tiktok.testimonialCards.card",
        3,
        [TestimonialLuca, TestimonialJessica, TestimonialAndrea],
        "title",
        "caption",
        "img"
      ),
    [TestimonialLuca, TestimonialJessica, TestimonialAndrea]
  )

  const faqsTextArray = React.useMemo(() => {
    return createArrayFromTranslationQuery(
      t,
      "tiktok.faqList.faq",
      3,
      [],
      "title",
      "content",
      "link"
    ).map(el => {
      return { ...el, content: faqLinkParser(el.content, el.link) }
    })
  }, [])

  const handleCardButtonClick = React.useCallback(() => {
    var userAgent = typeof window != "undefined"

    if (userAgent) {
      if (lng === "fr") {
        window.open("https://young-step.onelink.me/I1Zp/TikTokFR")
      } else {
        window.open("https://young-step.onelink.me/I1Zp/TikTok")
      }

      createGAEvent("click_cta", "inizia_ora_step")()
    }
  }, [])

  return (
    <Layout disableFooter>
      <Seo originalPath={i18n.originalPath} seoIdentifier="/" lang={lng} />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Container size="fullwidth">
        <Box maxWidth="756px" mx="auto" mt={["34px", "48px"]}>
          <StyledText
            line={["64px", "124px"]}
            fontWeight={700}
            dimension={["56px", "122px"]}
            as="h1"
            textAlign="center"
            dangerouslySetInnerHTML={{
              __html: t("tiktok.mainClaim"),
            }}
          ></StyledText>
        </Box>
        <Box mt={["24px", "34px"]} maxWidth={["328px", "505px"]} mx="auto">
          <StyledText
            fontWeight={400}
            line={["29px", "39px"]}
            dimension={["24px", "34px"]}
            textAlign="center"
            as="h4"
          >
            {t("tiktok.secondClaim")}
          </StyledText>
        </Box>
        <Box mt={["24px", "34px"]}>
          <StyledBox
            borderRadius="16px"
            overflow="hidden"
            style={{
              transform: "translateZ(0)",
            }}
          >
            <StyledLink
              href={
                lng === "fr"
                  ? "https://young-step.onelink.me/I1Zp/TikTokFR"
                  : "https://young-step.onelink.me/I1Zp/TikTok"
              }
              onClick={createGAEvent("click_cta", "download_step")}
            >
              <img
                src={GooglePlayStore}
                alt="Downaload From Google PlayStore"
              />
            </StyledLink>
            <StyledLink
              href={
                lng === "fr"
                  ? "https://young-step.onelink.me/I1Zp/TikTokFR"
                  : "https://young-step.onelink.me/I1Zp/TikTok"
              }
              onClick={createGAEvent("click_cta", "download_step")}
            >
              <img
                src={AppleStore}
                alt="Downaload From Apple Store"
                style={{
                  width: "inherit",
                }}
              />
            </StyledLink>
          </StyledBox>
        </Box>

        <Container mt={["34px", "48px"]}>
          <Box maxWidth="949px" height={["auto", "542px"]} margin="auto">
            <HeroImages />
          </Box>
        </Container>
        <Spacer height={["0px", "192px"]} />
        <CardsWrapperSection>
          {homeCardsArray.map((el, index) => {
            return <HomeCardSection {...el} key={index} />
          })}
        </CardsWrapperSection>
        <Spacer height={["90.5px", "193px"]} />
        <Container size={["lg", "md"]}>
          <Text type="display" fontSize={isMobile ? "xsmall" : "large"}>
            {parseColor(t("tiktok.testimonialClaim"), "#FF298A")}
          </Text>
        </Container>
        <TestimonialCardsSection array={homeTestimonialCards} />
        {/* Start Faq Component */}
        <Container>
          <FaqComponent
            style={{
              paddingTop: "0px",
              paddingBottom: "0px",
              marginTop: "0px",
            }}
            title={t("tiktok.faqTitle")}
            faqs={faqsTextArray}
            link={"https://youngplatform.com"}
            footer={t("tiktok.faqFooterTitle", "#FF298A")}
          />
        </Container>
        {/* Start Big Card Component */}
        <LastCardContainer mt={["83px", "192px"]} size={["fullwidth", "lg"]}>
          <StartYoung
            width="460px"
            big="true"
            icon={StepIcon}
            titleText={
              isMobile
                ? t("tiktok.lastCardClaim").replace(/\r?\n|\r/g, " ")
                : t("tiktok.lastCardClaim")
            }
            buttonText={t("tiktok.lastCardBtnClaim")}
            buttonIcon={"arrow_right"}
            textStyle={{ whiteSpace: "break-spaces" }}
            background={"#E81F76"}
            onButtonClick={handleCardButtonClick}
          />
        </LastCardContainer>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
export default IndexPage
